import React from "react"
import FirstStepSecondTab from "../components/firstStepSecondTab/FirstStepSecondTab";

const Step1Tab2 = () => {
	return (
		<>
			<FirstStepSecondTab/>
		</>
	)
};

export default Step1Tab2
